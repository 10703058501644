@use "../../styles/partials/glow" as *;
@use "../../styles/partials/sidebar" as *;

.servers {
  &__container {
    display: flex;
    justify-content: flex-end;
    width: 30%;
    color: #38a83a;
    @include glowGreen;
    text-align: center;
  }
  &__owned {
    border: #38a83a 1px dashed;
    border-radius: 10px;
    padding: 0.5rem;
  }
  &__list {
    text-decoration: none;
    list-style-type: none;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
  }
  &__item {
    padding: 0.5rem 0;
    border: #38a83a dashed 1px;
    border-radius: 10px;
    margin: 0.5rem 0;
    &:hover {
      @include glowGreenHover;
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__current {
    display: flex;
    flex-direction: column;
    align-items: first baseline;
  }
}

.logout__button {
  border: #38a83a 1px dashed;
  width: 10rem;
  border-radius: 10px;
  margin: 0 1rem;
  &:hover {
    @include glowGreenHover;
  }
}
.nav__bar {
  display: flex;
}
.help {
  margin: 0 1rem;
  border: #38a83a 1px dashed;
  @include glowGreen;
  border-radius: 10px;
  padding: 0 2rem;
  &:hover {
    @include glowGreenHover;
  }
}
