@use "../styles/partials/glow" as *;

.login {
  &__header {
    color: #38a83a;
    width: 25rem;
    padding: 0 3rem;
    font-size: 2rem;
    @include glowGreen;
  }
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20rem;
    background-color: black;
    align-items: first baseline;
  }
  &__card {
    display: flex;
    flex-direction: column;
    background-color: black;
    width: 25rem;
    height: 25rem;
    justify-content: center;
    align-items: flex-start;
  }
  &__link {
    padding: 2rem;
    font-size: 4rem;
    text-decoration: none;
    color: #38a83a;
    width: 25rem;
    @include glowGreen;
    &:hover {
      @include glowGreenHover;
    }
  }
  &__field {
    margin-bottom: 1rem;
  }
  &__label {
    font-size: 2rem;
    color: #38a83a;
    @include glowGreen;
  }
  &__button {
    background: black;
    color: #38a83a;
    font-size: 1.5rem;
    @include glowGreen;
    &:hover {
      @include glowGreenHover;
    }
    border: none;
  }
}
