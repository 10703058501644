@use "../../styles/partials/glow" as *;
@use "../../styles/partials/sidebar" as *;

.list {
  &__container {
    &--open {
      background-color: black;
      width: 100%;
      padding: 1.5rem;
      border-radius: 4px;

      transition: all 1s;
      -webkit-transition: all 0.25s;
    }
    &--closed {
      width: 0;
      transition: all 0.25s;
      -webkit-transition: all 0.25s;
    }
  }
  &__content {
    &--open {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }
    &--closed {
      display: none;
    }
  }
}

.skill {
  &__container {
    display: flex;
    color: #38a83a;
    width: 15%;
    @include glowGreen;
  }
  &__list {
    list-style-type: none;
    padding: 0.5rem 0;
  }
  &__item {
    border: #38a83a 1px dashed;
    border-radius: 10px;
    padding: 0 1rem;
    text-align: center;
    margin: 1rem 0;
    &:hover {
      @include glowGreenHover;
    }
  }
  &__add {
    text-align: center;
    border: #38a83a 1px dashed;
    border-radius: 10px;
    @include glowGreen;
    &:hover {
      @include glowGreenHover;
    }
  }
  &__modal {
    background-color: black;
    color: #38a83a;
    width: 50vw;
    margin: 15rem auto;
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include glowGreen;
  }

  &__details {
    margin-top: 1rem;
    padding: 1rem;
    border: #38a83a 1px dashed;
  }
}
.modal {
  &__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.sidebar__button--left {
  @include sidebar__buttonLeft;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.sidebar__button--right {
  @include sidebar__buttonRight;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
