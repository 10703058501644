@use "../../styles/partials/glow" as *;

.play {
  &__container {
    width: 60%;
    padding: 1rem;
  }
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__input {
    background-color: black;
    border: #38a83a 1px dashed;
    color: #38a83a;
    height: 2rem;
    padding: 0 1rem;
    @include glowGreen;
    border-radius: 10px;
  }
  &__button {
    width: 10rem;
    margin: 25rem auto;
    background-color: black;
    color: #38a83a;
    @include glowGreen;
    border-radius: 10px;
    height: 2rem;
    border: #38a83a 1px dashed;
    &:hover {
      @include glowGreenHover;
    }
  }
}
.error {
  color: red;
  text-align: center;
  @include glowRed;
}
::placeholder {
  color: #38a83a;
  opacity: 1;
}
